<template>
    <div>
        <section id='AICLinks' class="content-container" name='AIC Links'>
            <div class="titles">
                <h2>Connecting Through Touchpoints</h2>
                <p>Our many touchpoints – from physical walk-in centres to our online presence – ensure we remain close to seniors and caregivers who need help finding the relevant information to help them make care decisions.</p>
                
            </div>

            <div class="col-12 mt-5">
                <h4>AIC Links</h4>
            </div>

            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_1.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">A care consultant attending to a client at AIC Link @ Singapore General Hospital</p>
            <div class="col-12 px-3">
                <p>Our care consultants at AIC Links assist walk-in customers with information on health and social care assistance, referrals for Community Care services, and applications for financial assistance schemes managed by AIC.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-md-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_01.png" alt="">
                        </div>
                        <div class="col-md-8 text-2">
                            <p>There are <span class="stats">12</span> AIC Links located in public and community hospitals, in the community and at our headquarters on Maxwell Road. More than <span class="stats">120,000</span> people have been served at AIC Links.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='AICHotline' class="content-container" name='AIC Hotline'>
            <div class="titles">
                <h4>AIC Hotline</h4>
            </div>
            <div class="col-12 px-3">
                <p>Our hotline (1800-650-6060) provides assistance to seniors and caregivers by assessing and identifying their care needs. We share information on AIC financial schemes, eldercare and caregiving support options, and encourage them to participate in active ageing programmes. Our hotline also connects callers to other relevant agencies for help.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_02.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>The hotline has received over <span class="stats">360,000</span> calls.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='AICOnline' class="content-container" name='AIC Online'>
            <div class="titles">
                <h4>AIC Online</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_2.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">AIC connects with the public through digital platforms such as social media and our website</p>
            <div class="col-12 px-3">
                <p>With more people searching for information online, we have expanded our digital presence beyond our <a href="https://www.aic.sg">website</a>, a one-stop resource portal for seniors and caregivers, and <a href="https://www.facebook.com/AICSingapore">Facebook</a>. In 2020, we launched <a href="https://www.instagram.com/aic_singapore/">Instagram</a> and <a href="https://www.linkedin.com/company/agency-for-integrated-care-singapore/">LinkedIn</a> accounts to reach out to a younger audience in their 20s and 30s, as well as working adults.<br><br> Through our digital platforms, we share information and resources on ageing, care support, as well as career and scholarship opportunities with AIC and the Community Care sector.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_03.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Between April 2020 and March 2021, there were more than <span class="stats">580,000</span> visitors to our website.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_04.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>We have over <span class="stats">40,000</span> followers on Facebook, over <span class="stats">12,000</span> followers on LinkedIn and over <span class="stats">1,600</span> followers on Instagram.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'ConnectingThroughTouchpoints',
}
</script>
