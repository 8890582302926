`<template>
    <div>
        <section id='PrimaryCareNetworks' class="content-container" name='Primary Care Networks (PCNs)'>
            <div class="titles">
                <h2>Strengthening Primary Care</h2>
                <h4>Primary Care Networks (PCNs)</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_30.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">A PCN comprises care coordinators, doctors and nurse counsellors to support patients in managing their chronic conditions.</p>
            <div class="col-12 px-3">
                <p>A PCN is a group of like-minded GPs supported by shared nursing and care coordination resources providing holistic care for patients with chronic diseases such as diabetes, high blood pressure and high cholesterol. Patients can continue to manage their conditions with their family doctors near home, instead of travelling to specialist outpatient clinics. Additional services offered at PCNs include Diabetic Foot Screening, Diabetic Retinal Photography and Nurse Counselling.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_63.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are a total of <span class="stats">10</span> PCNs today.</p>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_64.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>As of December 2020, more than <span class="stats">500</span> clinics have enrolled in the PCN scheme.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_65.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>As of December 2020, the PCNs are managing the care of over <span class="stats">130,000</span> patients.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='EnhancingPCNNurseCounselling' class="content-container" name='Enhancing PCN Nurse Counselling'>
            <div class="titles">
                <h5>Enhancing PCN Nurse Counselling</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_31.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">A PCN nurse conducting an eye check for a patient with diabetes</p>
            <div class="col-12 px-3">
                <p>Together with MOH and partners, AIC continued to strengthen and enhance the capabilities of PCN nurse counsellors in areas such as patient education, cognitive testing and preventive screening.<br><br> We launched the inaugural Nurse Preparatory Course to prepare our nurse counsellors to deliver quality care and services to better support patients. We organised regular team-based training for PCN nurse counsellors to deepen their knowledge in chronic disease management, sharpen their skills and strengthen their network. Nurses from ancillary providers also participated in these training sessions. AIC also developed resources such as the patient logbook and a series of resource books to support nurse counsellors.</p>
            </div>
        </section>
        <section id='CommunityHealthCentres' class="content-container" name='Community Health Centres (CHCs)'>
            <div class="titles mt-5">
                <h4>Community Health Centres (CHCs)</h4>
            </div>
            <div class="col-12 px-3">
                <p>These centres partner GPs to provide holistic care to patients with chronic illnesses. CHCs support GPs by providing their patients with essential ancillary services such as nurse counselling, diabetic foot and eye screening. Through personalised care plans and targeted interventions, the nurse counsellors support patients in keeping their chronic conditions under control. Regular diabetic screening also helps to detect foot complications and eye diseases early.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_66.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>The CHCs have supported more than <span class="stats">1,000</span> GPs across Singapore.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='ScreeningandVaccinationSubsidies' class="content-container" name='Screening and Vaccination Subsidies'>
            <div class="titles">
                <h4>Screening and Vaccination Subsidies</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_32.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">A GP administering vaccinations under the NCIS at her clinic</p>
            <div class="col-12 px-3">
                <p>On 1 November 2020, MOH enhanced the subsidies for vaccinations and screenings under the National Adult Immunisation Schedule, the National Childhood Immunisation Schedule (NCIS) and Childhood Developmental Screening. Singaporeans can receive these subsidised vaccinations and screenings at <a href="#CommunityHealthAssistScheme">CHAS</a> clinics and polyclinics. <br><br>
                AIC supported MOH in the policy development and implementation of these schemes. We organised surveys and focus group discussions with our GPs to gather feedback for policy development.We tested the MOH Healthcare Claims Portal system with GPs and conducted planning exercises with pharmaceutical companies to ensure the smooth execution of vaccination logistics. AIC also conducted briefings and training sessions, and produced materials such as an infokit and a guidebook for GP clinics.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_67.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">1,200</span> GPs have been supported in the rollout of the schemes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'StrengtheningPrimaryCare',
}
</script>