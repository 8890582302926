<template>
    <div>
        <section id='SilverGenerationOffice' class="content-container" name='Silver Generation Office (SGO)'>
            <div class="titles">
                <h2>Reaching Out & Spreading the Word</h2>
                <h4>Silver Generation Office (SGO)</h4>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/r8mtTdGrtVU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p>SGO connects with seniors through house visits and phone calls by Silver Generation (SG) Ambassadors. These trained volunteers share updates on government schemes, preventive health tips, basic digital tools and skills, and link seniors to Community Care services.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_05.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>SGO operates out of <span class="stats">20</span> satellite offices islandwide.</p>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_06.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are currently about <span class="stats">3,000</span> SG Ambassadors.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_07.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>They have carried out over <span class="stats">1.5 million</span> engagements since 2014.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CommunityNetworksSeniors' class="content-container" name='Community Networks for Seniors'>
            <div class="titles">
                <h5>Community Networks for Seniors (CNS)</h5>
                <p>SGO supports seniors in their ageing aspirations and needs by working with other community partners through this initiative. CNS has four areas of focus: active ageing, befriending, care and support and digital adoption.
                </p>
            </div>
        </section>
        <section id='ActiveAgeing' class="content-container" name='Active Ageing'>
            <div class="titles">
                <h5>Active Ageing</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_3.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">We encourage seniors to stay active through activities such as exercise sessions organised by partners</p>
            <div class="col-12 px-3">
                <p>SGO works with the People’s Association (PA), the Health Promotion Board (HPB), SportSG as well as other partners to bring social and physical activities, health education, and preventive health services (eyes, hearing and oral health screenings) closer to seniors so that they can stay active, healthy and engaged.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_08.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Community partners have established over <span class="stats">650</span> Active Ageing nodes nationwide.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_09.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>As of December 2020, Over <span class="stats">77,000</span> seniors have benefited from functional health screenings.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='Befriending' class="content-container" name='Befriending'>
            <div class="titles">
                <h5>Befriending</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_4.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">An SG Ambassador visiting a senior at her home to check in on her</p>
            <div class="col-12 px-3">
                <p>SGO works with community befriending providers to regularly check on seniors who are lonely or at risk of social isolation. We have also worked with Changi General Hospital to make CareLine, their round-the-clock tele-befriending service, available to this group. In addition, SGO partnered CareLine and Temasek Foundation in June 2020 to distribute smartphones with a 12-month data plan to seniors who did not own a phone.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_10.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">5,000</span> seniors have been signed up for befriending services.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_11.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">230</span> smartphones have been distributed to vulnerable seniors.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CareandSupport' class="content-container" name='Care & Support'>
            <div class="titles">
                <h5>Care & Support</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_5.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">SG Ambassadors go the extra mile to connect seniors to the care they need</p>
            <div class="col-12 px-3">
                <p>SGO works with Regional Health Systems, social service agencies, government agencies, grassroots organisations and faith-based institutions to provide care coordination and assistance for frail seniors to age with dignity in the community and with their families.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_12.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">3,000</span> seniors have been assisted by SGO in this manner as of December 2020.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='DigitalAdoption' class="content-container" name='Digital Adoption'>
            <div class="titles">
                <h5>Digital Adoption</h5>
            </div>
            <div class="col-12 px-3">
                <p>Beyond conventional care and support, SGO is also stepping up efforts to help seniors pick up digital skills so they become more digitally savvy. We are also working to connect seniors to partners and programmes with digital resources.</p>
            </div>
        </section>
        <section id='SGOTurns5' class="content-container" name='SGO Turns 5'>
            <div class="titles">
                <h5>SGO Turns 5</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_6.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Award recipients joining PM Lee and distinguished guests to sing SGO a birthday song</p>
            <div class="col-12 px-3">
                <p>SGO’s 5th anniversary appreciation lunch on 1 September 2019 was graced by Prime Minister Lee Hsien Loong. The event saw the launch of the inaugural SG Ambassador Awards, which recognises the important role of SG Ambassadors in supporting seniors to live and age well. <br><br> The SG Ambassador Service Award recognises dedicated active volunteers, while the Exemplary SG Ambassador Award recognises outstanding individuals who have gone the extra mile in supporting seniors.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_13.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>About <span class="stats">2,800</span> guests attended the anniversary event.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_14.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">1,000</span> SG Ambassadors were recognised across the two award categories.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='MerdekaGenerationPackage' class="content-container" name='Merdeka Generation Package'>
            <div class="titles">
                <h4>Merdeka Generation Package (MGP) Outreach</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_7.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">The Government introduced the MGP in 2019 to honour and thank our Merdeka Generation for their contributions</p>
            <div class="col-12 px-3">
                <p>AIC supported various government agencies in the rollout of MGP in 2019. We worked with the Ministry of Health (MOH) and the Ministry of Communications and Information to produce and deliver Merdeka Generation (MG) folders to eligible seniors. The AIC hotline was publicised as the MGP healthcare enquiries hotline, managing public queries on eligibility and benefits as well as the folder mailout. SGO was tasked with reaching out to MG seniors on this scheme and provided support at MGP roadshows organised by various agencies.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_15.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Nearly <span class="stats">500,000</span> MG folders were distributed.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_16.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>SGO supported over <span class="stats">200</span> MGP roadshows and community events.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='MentalHealthAwareness' class="content-container" name='Mental Health Awareness'>
            <div class="titles">
                <h4>Mental Health Awareness</h4>
                <p>AIC raises awareness of mental health through public events and the development of educational resources, among other efforts.</p>
            </div>
        </section>
        <section id='VirtualEvents' class="content-container" name='Virtual Events'>
            <div class="titles">
                <h5>Virtual Events</h5>
            </div>
            <div class="col-12 px-3">
                <p>Together with HPB, the Institute of Mental Health and the National Council of Social Service, AIC worked with over 20 community care partners to organise a series of webinars and activities to commemorate World Mental Health Day in October 2020. The theme ‘Emerging Mentally Stronger Together – Strategies for a New Normal’ aimed to promote mental wellness and bring a message of encouragement during COVID-19.<br><br> To commemorate World Alzheimer’s Day, AIC partnered Enable Asia’s Enabling Festival, which ran for three weeks in September and October 2020. The event centred on the theme of sight, with the tagline ‘Do You See What I See’. An innovative combination of art and science brought to life inspiring stories of people living with dementia and their caregivers.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_17.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>The events for World Mental Health Day 2020 reached over <span class="stats">1 million</span> Facebook users.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_18.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>The Enabling Festival 2020 was attended by over <span class="stats">5,500</span> participants.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='TrainingResources' class="content-container" name='Training & Resources'>
            <div class="titles">
                <h5>Training & Resources</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_8.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">E-learning modules on mental health and dementia can be found on online</p>
            <div class="col-12 px-3">
                <p>AIC has been training frontline workers from government agencies, volunteers and community partners to better understand and support people with mental health conditions that they may encounter in their work. Participants learn more about basic mental health awareness through e-modules while role play and case discussions take place in classrooms, which went virtual during COVID-19. 

                <br><br> To help the public better manage their mental wellness and care for loved ones living with mental health conditions, we developed resources such as ‘Living with Dementia: A Resource Kit for Caregivers’ and worked with Our Grandfather Story on a video titled ‘Caring for Persons with Dementia’ in their Can Ask Meh series. The public can also access the e-training modules for frontline officers <a href="https://www.aic.sg/mental-wellness-dementia/mental-health-elearning">online</a> and follow our Facebook pages <a href="https://www.facebook.com/DementiaFriendlySingapore">Dementia-Friendly Singapore</a> and <a href="https://www.facebook.com/MentalHealthAwarenessSG">Mental Health Awareness Singapore</a>.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_20.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>To date, over <span class="stats">36,000</span> frontline officers have been trained.</p>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_21.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">20</span> resources for the public were developed in 2019 and 2020.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_22.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are over <span class="stats">6,000</span> followers for the Dementia-Friendly Singapore Facebook page, and over <span class="stats">4,600</span> followers for the Mental Health Awareness Singapore Facebook page.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='PublicityCommunityHealthAssistScheme' class="content-container" name='Publicity for Community Health Assist Scheme (CHAS) Enhancements'>
            <div class="titles">
                <h4>Publicity for Community Health Assist Scheme (CHAS) Enhancements</h4>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/R_ces3e_Hq0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p>When <a href="#CommunityHealthAssistScheme">CHAS</a> was enhanced in 2019 to allow all Singaporeans to receive subsidies at participating CHAS clinics, we rolled out a publicity campaign to raise awareness of the enhancements and organised public engagement events such as CHAS carnival and roadshows. Since December 2019, we also worked with PA and the Public Service Division to bring CHAS on board as one of the services available at the Integrated Public Service Centre (IPSC) and self-help terminals, starting with IPSC @ Our Tampines Hub.</p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'ReachingOutSpreadingWord',
}
</script>
<style scoped>
.line{
	border-bottom: 2px solid white;
}

.titles {
    margin-top: 40px;
}
</style>


