var level_3_0_0 = {
  "0" : {
    "name": "Community Networks for Seniors (CNS)",
    "hash": "CommunityNetworksSeniors"
  },
  "1" : {
    "name": "Community Networks for Seniors: Active Ageing",
    "hash": "ActiveAgeing"
  },
  "2" : {
    "name": "Befriending",
    "hash": "Befriending"
  },
  "3" : {
    "name": "Care & Support",
    "hash": "CareandSupport"
  },
  "4" : {
    "name": "Digital Adoption",
    "hash": "DigitalAdoption"
  },
  "5" : {
    "name": "SGO Turns 5",
    "hash": "SGOTurns5"
  }
};

var level_3_0_2= {
  "0" : {
    "name": "Virtual Events",
    "hash": "VirtualEvents"
  },
  "1" : {
    "name": "Training & Resources",
    "hash": "TrainingResources"
  },
}

var level_3_2_1 = {
  "0" : {
    "name": "Home Caregiving Grant (HCG)",
    "hash": "HomeCaregivingGrant"
  },
  "1" : {
    "name": "ElderFund",
    "hash": "ElderFund"
  },
  "2" : {
    "name": "CareShield Life & MediSave Care",
    "hash": "CareShieldLifeMediSaveCare"
  },
};

var level_3_2_2= {
  "0" : {
    "name": "Community Health Assist Scheme (CHAS)",
    "hash": "CommunityHealthAssistScheme"
  },
  "1" : {
    "name": "Pioneer Generation Disability Assistance Scheme (PioneerDAS)",
    "hash": "PioneerGenerationDisabilityAssistanceScheme"
  },
  "3" : {
    "name": "Interim Disability Assistance Programme for the Elderly (IDAPE)",
    "hash": "InterimDisabilityAssistanceProgramme"
  },
  "4" : {
    "name": "Foreign Domestic Worker Levy Concession for Persons with Disabilities",
    "hash": "ForeignDomesticWorker"
  },
  "5" : {
    "name": "Seniors’ Mobility & Enabling Fund (SMF)",
    "hash": "SeniorsMobilityEnablingFund"
  },
}

var level_3_4_0 = {
  "0" : {
    "name": "Home Care & Senior Care Centres (SCCs)",
    "hash": "HomeCareSeniorCareCentres"
  },
  "1" : {
    "name": "Active Ageing Hubs (AAHs)",
    "hash": "ActiveAgeingHubs"
  },
  "2" : {
    "name": "Senior Activity Centres (SACs)",
    "hash": "SeniorActivityCentres"
  },
  "3" : {
    "name": "Care Close to Home (C2H)",
    "hash": "CareClosetoHome"
  },
  "4" : {
    "name": "Integrated Home & Day Care (IHDC)",
    "hash": "IntegratedHomeandDayCare"
  },
  "5" : {
    "name": "Healthy Ageing Promotion Programme for You (HAPPY)",
    "hash": "HealthyAgeingPromotionProgramme"
  },
  "6" : {
    "name": "Community Case Management Service (CCMS)",
    "hash": "CommunityCaseManagementService"
  },
  "7" : {
    "name": "Centre-based Transport",
    "hash": "CentrebasedTransport"
  },
  "8" : {
    "name": "Meals-On-Wheels (MOW)",
    "hash": "MealsonWheels"
  },
  "9" : {
    "name": "Medical Escort & Transport (MET)",
    "hash": "MedicalEscortTransport"
  },
}

var level_3_4_1 = {
  "0" : {
    "name": "Hospital-to-Home (H2H)",
    "hash": "HospitaltoHome"
  },
  "1" : {
    "name": "Outpatient to Community",
    "hash": "OutpatienttoCommunity"
  },
}

var level_3_5_1 = {
  "0" : {
    "name": "Dementia-Friendly Community (DFC)",
    "hash": "DementiaFriendlyCommunity"
  },
  "1" : {
    "name": "Go-To Points (GTPs)",
    "hash": "GoToPoints"
  },
  "2" : {
    "name": "Dementia Friends Mobile App",
    "hash": "DementiaFriendsMobileApp"
  },
}

var level_3_6_0 = {
  "0" : {
    "name": "Enhancing PCN Nurse Counselling",
    "hash": "EnhancingPCNNurseCounselling"
  },
}

var level_2_0 = {
  '0' : {
    name : 'AIC Links',
    hash : 'AICLinks',
  },
  '1' : {
    name : 'AIC Hotline',
    hash : 'AICHotline',
  },
  '2' : {
    name : 'AIC Online',
    hash : 'AICOnline',
  }
}

var level_2_1 = {
  '0' : {
    name: 'Silver Generation Office (SGO)',
    hash: 'SilverGenerationOffice',
    links : level_3_0_0 
  },
  '1' : {
    name: 'Merdeka Generation Package (MGP) Outreach',
    hash: 'MerdekaGenerationPackage',
  },
  '2' : {
    name: 'Mental Health Awareness',
    hash: 'MentalHealthAwareness',
    links : level_3_0_2
  },
  '3': {
    name: 'Publicity for Community Health Assist Scheme (CHAS) Enhancements',
    hash: 'PublicityCommunityHealthAssistScheme',
  },
}

var level_2_2 = {
  '0' : {
    name: 'eServices for Financing Schemes (eFASS) Application Portal',
    hash: 'eServicesFinancingSchemes',
  },
  '1' : {
    name: 'New Schemes',
    hash: 'NewSchemes',
    links : level_3_2_1
  },
  '2': {
    name: 'Existing Schemes',
    hash: 'ExistingSchemes',
    links : level_3_2_2
  },
  '3': {
    name: 'Caregivers Training Grant (CTG)',
    hash: 'CaregiversTrainingGrant',
  },
  
}

var level_2_3 = {
  '0' : {
    name: 'Caregiver Respite',
    hash: 'CaregiverRespite',
  },
  '1' : {
    name: 'Caregiver Community Outreach Teams',
    hash: 'CaregiverCommunityOutreachTeams',
  },
  '2' : {
    name: 'Caregiver Support Networks (CSNs)',
    hash: 'CaregiverSupportNetworks',
  },
}

var level_2_4 = {
  '0' : {
    name: 'Home & Centre-based Care Services',
    hash: 'HomeCentrebasedCareServices',
    links : level_3_4_0
  },
  '1' : {
    name: 'From Hospital to Community',
    hash: 'FromHospitaltoCommunity',
    links : level_3_4_1
  },
  '2' : {
    name: 'Nursing Homes',
    hash: 'NursingHomes',
  },
  '3' : {
    name: 'Advance Care Planning (ACP)',
    hash: 'AdvanceCarePlanning',
  },
}

var level_2_5 = {
  '0' : {
    name: 'Community Mental Health Network',
    hash: 'CommunityMentalHealthNetwork',
  },
  '1' : {
    name: 'Dementia-Friendly Singapore (DFSG)',
    hash: 'DementiaFriendlySingapore',
    links : level_3_5_1
  },
  '2' : {
    name: 'Building Partner Capabilities',
    hash: 'BuildingPartnerCapabilities',
  },
}

var level_2_6 = {
  '0' : {
    name: 'Primary Care Networks (PCNs)',
    hash: 'PrimaryCareNetworks',
    links : level_3_6_0
  },
  '1' : {
    name: 'Community Health Centres (CHCs)',
    hash: 'CommunityHealthCentres',
  },
  '2' : {
    name: 'Screening and Vaccination Subsidies',
    hash: 'ScreeningandVaccinationSubsidies',
  },
}


var level_1 = {
  '0' : {
    name: 'Connecting Through Touchpoints',
    sections: level_2_0,
    active: false
  },

  '1' : {
    name: 'Reaching Out & Spreading the Word',
    sections: level_2_1,
    active:false
  },
  '2' : {
    name: 'Extending Financial Assistance',
    sections: level_2_2,
    active:false
  },
  '3' : {
    name: 'Supporting Caregivers',
    sections: level_2_3,
    active:false
  },
  '4' : {
    name: 'Facilitating Care Services',
    sections: level_2_4,
    active:false
  },
  '5' : {
    name: 'Supporting Community Mental Health',
    sections: level_2_5,
    active:false
  },
  '6' : {
    name: 'Strengthening Primary Care',
    sections: level_2_6,
    active:false
  },
}

export default level_1; 