<template>
    <div>
        <section id='eServicesFinancingSchemes' class="content-container" name='eServices for Financing Schemes'>
            <div class="titles">
                <h2>Extending Financial Assistance</h2>
                <h4>eServices for Financing Schemes (eFASS) Application Portal</h4>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/uBccAgZJSro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p>This <a href="https://efinance.aic.sg/">portal</a> was launched on 1 October 2020 to enable users to conveniently apply for and manage long-term care schemes for themselves or their loved ones.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_23.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>About <span class="stats">6,500</span> applications have been processed via the eFASS portal.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='NewSchemes' class="content-container" name='New Schemes'>
            <div class="titles">
                <h4>New Schemes</h4>
            </div>
        </section>
        <section id='HomeCaregivingGrant' class="content-container" name='Home Caregiving Grant (HCG)'>
            <div class="titles">
                <h5>Home Caregiving Grant (HCG)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_9.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Caregivers can better care for their loved ones with the financial support provided by HCG</p>
            <div class="col-12 px-3">
                <p>HCG allows caregivers of loved ones with moderate to severe disabilities to receive $200 a month to defray the costs of caregiving, e.g. eldercare and caregiver support services in the community, hiring a domestic helper, etc. It was launched in October 2019 to replace the Foreign Domestic Worker Grant.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_24.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">31,000</span> caregivers have benefited from HCG.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='ElderFund' class="content-container" name='ElderFund'>
            <div class="titles">
                <h5>ElderFund</h5>
            </div>
            <div class="col-12 px-3">
                <p>Needy Singaporeans with severe disabilities who are not eligible for CareShield Life, ElderShield or IDAPE can receive up to $250 a month from this scheme, which was launched in January 2020.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_25.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">1,100</span> Singaporeans have received payouts through ElderFund.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CareShieldLifeMediSaveCare' class="content-container" name='CareShield Life & MediSave Care'>
            <div class="titles">
                <h5>CareShield Life &#38; MediSave Care</h5>
            </div>
            <div class="col-12 px-3">
                <p>In October 2020, MOH, AIC and the Central Provident Fund Board launched the CareShield Life and MediSave Care schemes. These two schemes provide Singaporeans with greater assurance and support in their long-term care needs in the event of severe disability, especially in old age. <br><br>The former is a long-term care insurance scheme that provides monthly cash payouts starting at $600. The latter allows Singapore citizens and Permanent Residents to withdraw up to $200 each month from their or their spouses’ MediSave accounts. AIC supports the administration of the schemes and the assessment of applicants’ disability status.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_26.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>As of December 2020, around <span class="stats">700,000</span> Singaporeans were covered by CareShield Life.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_27.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">170</span> people have benefited from MediSave Care.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='ExistingSchemes' class="content-container" name='Existing Schemes'>
            <div class="titles">
                <h4>Existing Schemes</h4>
            </div>
        </section>
        <section id='CommunityHealthAssistScheme' class="content-container" name='Community Health Assist Scheme'>
            <div class="titles">
                <h5>Community Health Assist Scheme (CHAS)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_10.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">A senior presents her CHAS card at a GP clinic to enjoy subsidies for her check-up</p>
            <div class="col-12 px-3">
                <p>In November 2019, CHAS was enhanced to enable all Singapore Citizens to receive subsidies for medical and/or dental care at participating CHAS clinics. Changes also include revised income criteria to benefit more Singaporeans, chronic illness subsidy increases for the CHAS Blue and Orange tiers, a new CHAS Green tier, a new common illness subsidy for the CHAS Orange, and special subsidies for MG seniors.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_28.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">2 million</span> Singaporeans can access subsidised care at CHAS general practitioner (GP) clinics.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='PioneerGenerationDisabilityAssistanceScheme' class="content-container" name='Pioneer Generation Disability Assistance'>
            <div class="titles">
                <h5>Pioneer Generation Disability Assistance Scheme (PioneerDAS)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_11.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Pioneers who require assistance with three or more activities of daily living can apply for PioneerDAS</p>
            <div class="col-12 px-3">
                <p>Eligible pioneers with moderate to severe disabilities can receive $100 a month for life to offset care costs.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_29.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">71,000</span> pioneers have benefited from PioneerDAS.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='InterimDisabilityAssistanceProgramme' class="content-container" name='Interim Disability Assistance Programme'>
            <div class="titles">
                <h5>Interim Disability Assistance Programme for the Elderly (IDAPE)</h5>
            </div>
            <div class="col-12 px-3">
                <p>Needy elderly Singaporeans with severe disabilities who are not eligible for ElderShield can receive $150 or $250 a month from this programme.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_30.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">13,000</span> seniors have benefited from IDAPE.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='ForeignDomesticWorker' class="content-container" name='Foreign Domestic Worker'>
            <div class="titles">
                <h5>Foreign Domestic Worker Levy Concession for Persons with Disabilities</h5>
            </div>
            <div class="col-12 px-3">
                <p>Caregivers who employ a foreign domestic worker to look after persons with disabilities can pay a lower levy of $60 a month with this scheme.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_31.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">12,000</span> caregivers have benefited from this scheme.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='SeniorsMobilityEnablingFund' class="content-container" name='Seniors’ Mobility & Enabling Fund'>
            <div class="titles">
                <h5>Seniors’ Mobility & Enabling Fund (SMF)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_12.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Subsidised mobility aids support seniors to continue with their daily routines, like meeting friends in the neighbourhood for chats</p>
            <div class="col-12 px-3">
                <p>This scheme provides subsidies for those who require devices (e.g. commode, wheelchair) and consumables (home healthcare items such as milk feeds and adult diapers) to support them in their daily activities and mobility.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_32.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">86,000</span> low-income seniors have benefited from SMF.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CaregiversTrainingGrant' class="content-container" name='Caregivers Training Grant (CTG)'>
            <div class="titles">
                <h5>Caregivers Training Grant (CTG)</h5>
            </div>
            <div class="col-12 px-3">
                <p>Caregivers can tap on this annual $200 grant to offset the cost of attending approved caregiver training courses and pick up skills to better care for their loved ones.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_33.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are currently over <span class="stats">200</span> courses offered by about <span class="stats">60</span> training providers.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_34.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>More than <span class="stats">60,000</span> people have tapped on the grant so far.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'ExtendingFinancialAssistance',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>

