<template>
    <div>
        <section id='CaregiverRespite' class="content-container" name='Caregiver Respite'>
            <div class="titles">
                <h2>Supporting Caregivers</h2>
                <p>Since MOH’s launch of the Caregiver Support Action Plan to strengthen support for caregiving, AIC has been working with MOH to roll out various related initiatives.</p>
            </div>

            <div class="titles mt-5">
                <h4>Caregiver Respite</h4>
            </div>

            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_13.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Caregivers can enrol for Go Respite by filling up an application form <a href="https://www.aic.sg/caregiving/go-respite-pilot">online</a></p>
            <div class="col-12 px-3">
                <p>Three respite options were launched in 2019. The Go Respite programme allows caregivers to plan ahead (e.g. complete paperwork and select preferred care facilities) to tap on respite care offered at senior care centres and nursing homes. <br><br>The Night Respite programme supports caregivers of persons living with dementia who exhibit sundowning behaviour, i.e. challenging behaviour that escalates towards the end of the day. Their loved ones will be cared for at night so that caregivers can get some rest. Due to COVID-19, the Night Respite programme has been suspended till further notice.<br><br>For caregivers of end-of-life clients, there is Home-based Respite Care. Care professionals will step in to provide care to their loved ones for 10 to 12 hours, so that the caregivers can get some rest.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_35.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p><span class="stats">20</span> senior care centres and <span class="stats">26</span> nursing homes currently participate in Go Respite.</p>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_36.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There have been over <span class="stats">800</span> Go Respite applications to date.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_37.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Close to <span class="stats">200</span> individuals have benefited from Home-based Respite Care.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CaregiverCommunityOutreachTeams' class="content-container" name='Caregiver Community Outreach Teams'>
            <div class="titles">
                <h4>Caregiver Community Outreach Teams</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_14.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Self-care through wellness activities is one way to take a break so that caregivers can go the distance in caregiving</p>
            <div class="col-12 px-3">
                <p>These teams reach out to caregivers in the community at risk of depression, anxiety or burnout, providing them with social and emotional support and linking them to support groups and counselling services. The teams support caregivers in self-care through health and wellness activities, stress management and future planning.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_38.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are <span class="stats">4</span> caregiver community outreach teams supporting over <span class="stats">900</span> caregivers as of December 2020.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CaregiverSupportNetworks' class="content-container" name='Caregiver Support Networks (CSNs)'>
            <div class="titles">
                <h4>Caregiver Support Networks (CSNs)</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_15.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Caregivers taking a break and getting pampered at a self-care session organised by a CSN</p>
            <div class="col-12 px-3">
                <p>To support caregivers of individuals with physical and mental conditions, AIC has worked with Dementia-Friendly Communities (DFCs) to set up support networks. Through training, activities and peer sharing, CSNs encourage self-care, build peer support networks and recognise caregivers, giving them opportunities to step up as Peer Leaders.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_39.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Today, there are <span class="stats">7</span> CSNs in various DFCs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'SupportingCaregivers',
}
</script>
