<template>
    <div>
        <section id='HomeCentrebasedCareServices' class="content-container" name='Home & Centre-based Care Services'>
            <div class="titles">
                <h2>Facilitating Care Services</h2>
                <h4>Home & Centre-based Care Services</h4>
            </div>
        </section>
        <section id='HomeCareSeniorCareCentres' class="content-container" name='Home Care & Senior Care Centres'>
            <div class="titles">
                <h5>Home Care & Senior Care Centres (SCCs)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_16.jpg" alt="">
                    <p class="credits">Credit: Courtesy of St Luke’s ElderCare (This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Having meals together is an activity that many SCC clients look forward to</p>
            <div class="col-12 px-3">
                <p>Home nursing, home medical, home therapy and home personal care services are available for homebound seniors and those who prefer to receive care at home.</p>
                <p> SCCs provide day care services, exercise, therapy and other cognitive stimulating activities to maintain the wellbeing of frail seniors or those living with dementia, allowing their caregivers to go to work with peace of mind.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_40.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">19,000</span> seniors are benefiting from these subsidised services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='ActiveAgeingHubs' class="content-container" name='Active Ageing Hubs (AAHs)'>
            <div class="titles">
                <h5>Active Ageing Hubs (AAHs)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_17.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">An AAH client completing an activity to exercise his fingers and keep them nimble</p>
            <div class="col-12 px-3">
                <p>These hubs provide social and active ageing activities for seniors who are well, in addition to services such as day care and therapy for seniors who require more care.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_41.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are <span class="stats">13</span> AAHs islandwide.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='SeniorActivityCentres' class="content-container" name='Senior Activity Centres (SACs)'>
            <div class="titles">
                <h5>Senior Activity Centres (SACs)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_18.jpg" alt="">
                    <p class="credits">Credit: Courtesy of NTUC Health Senior Activity Centre (Marsiling) (This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">SACs offer activities for seniors to socialise with each other</p>
            <div class="col-12 px-3">
                <p>These centres provide social support and active ageing activities to residents in rental flats and studio apartments.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_42.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are about <span class="stats">130</span> SACs islandwide. From April 2021 onwards, SACs, <a href="#ActiveAgeingHubs">AAHs</a> and <a href="#HomeCareSeniorCareCentres">SCCs</a> will gradually come on board the <a href="/looking-ahead#ActiveAgeingCentreServiceModel">new Eldercare Centre service model</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CareClosetoHome' class="content-container" name='Care Close to Home (C2H)'>
            <div class="titles">
                <h5>Care Close to Home (C2H)</h5>
            </div>
            <div class="col-12 px-3">
                <p>The C2H pilot was launched in 2014 to assist seniors residing in selected public rental housing. Nurse-led care teams based at nearby SACs keep an eye on frail seniors and provide home care services so seniors can age at home. The C2H pilot and its clients will be transited to the <a href="/looking-ahead#ActiveAgeingCentreServiceModel">new Active Ageing Centre service model</a>. This new service model is similar to C2H’s service scope and will cover all seniors in Singapore.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_43.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>The C2H pilot is benefiting over <span class="stats">7,200</span> clients at <span class="stats">15</span> sites.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='IntegratedHomeandDayCare' class="content-container" name='Integrated Home & Day Care (IHDC)'>
            <div class="titles">
                <h5>Integrated Home & Day Care (IHDC)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_19.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">A home care nurse tending to a senior’s wounds at his home</p>
            <div class="col-12 px-3">
                <p>The IHDC programme was launched in 2016 for seniors with complex needs so they can continue to be cared for at home, instead of being admitted to a nursing home. A multidisciplinary care team customises an integrated plan that includes home and day care services, based on the client’s needs.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_44.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>IHDC has benefited more than <span class="stats">1,100</span> clients.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_45.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>IHDC is currently provided at <span class="stats">18</span> centres operated by <span class="stats">11</span>  providers islandwide.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='HealthyAgeingPromotionProgramme' class="content-container" name='Healthy Ageing Promotion Programme'>
            <div class="titles">
                <h5>Healthy Ageing Promotion Programme for You (HAPPY)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_20.jpg" alt="">
                    <p class="credits">Credit: Sunlove Senior Activity Centre</p>
                </div>
            </div>
            <p class="caption px-3">Seniors at a HAPPY session at an SAC</p>
            <div class="col-12 px-3">
                <p>In 2019, we collaborated with MOH and the National University Health System to roll out HAPPY to the sector. The programme promotes healthy ageing with exercises that improve muscle strength and memory, to prevent the early onset of dementia and frailty.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_46.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>AIC has commissioned over <span class="stats">70</span> HAPPY sites in <a href="#SeniorActivityCentres">SACs</a>, <a href="#ActiveAgeingHubs">AAHs</a> and <a href="#HomeCareSeniorCareCentres">SCCs</a> across Singapore.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_47.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>As of September 2020, more than <span class="stats">900</span> new seniors have joined HAPPY.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CommunityCaseManagementService' class="content-container" name='Community Case Management Service'>
            <div class="titles">
                <h5>Community Case Management Service (CCMS)</h5>
            </div>
            <div class="col-12 px-3">
                <p>This service assists vulnerable seniors and their caregivers who face difficulties in managing their multiple health and social care needs so that the seniors can continue living at home. Case managers assess clients’ care needs holistically, coordinate services, educate, advocate and empower clients and their caregivers to ensure they are supported in the community.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_48.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">1,300</span> seniors have been supported by <span class="stats">6</span> service partners.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CentrebasedTransport' class="content-container" name='Centre-based Transport'>
            <div class="titles">
                <h5>Centre-based Transport</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_21.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Specialised transport vans come with hydraulic lifts so that they can ferry those in wheelchairs</p>
            <div class="col-12 px-3">
                <p>Since 2014, AIC has supported partners in offering their clients more transport options in getting to eldercare centres. In 2021, we started a panel of specialised transport vendors that our centre-based partners can choose to work with for their transport needs. Clients can also continue to tap on alternative transport modes provided by other partners, such as ComfortDelGro’s scheduled taxi service or GrabAssist service to centres.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_49.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are currently <span class="stats">4</span> vendors on the specialised transport panel.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='MealsonWheels' class="content-container" name='Meals-On-Wheels (MOW)'>
            <div class="titles">
                <h5>Meals-On-Wheels (MOW)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_22.jpg" alt="">
                    <p class="credits">Credit: Youth Corps Singapore</p>
                </div>
            </div>
            <p class="caption px-3">A youth volunteer with TOUCH Home Care delivering daily meals to seniors’ doorsteps</p>
            <div class="col-12 px-3">
                <p>The service provides meal deliveries to homebound clients who are unable to buy and prepare their own meals and do not have a caregiver to help them to do so. The meals come in halal, non-halal, vegetarian and special diet (eg. blended, soft food) options.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_50.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p><span class="stats">7</span> MOW service providers are serving <span class="stats">6,000</span> meals islandwide.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='MedicalEscortTransport' class="content-container" name='Medical Escort & Transport (MET)'>
            <div class="titles">
                <h5>Medical Escort & Transport (MET)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_23.jpg" alt="">
                    <p class="credits">Credit: Handicaps Welfare Association</p>
                </div>
            </div>
            <p class="caption px-3">MET staff assisting a senior for an appointment</p>
            <div class="col-12 px-3">
                <p>This service provides transport for clients to attend medical appointments at polyclinics, specialist outpatient clinics and hospitals. It also provides escorts for clients who require assistance to move around.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_51.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p><span class="stats">12</span> MET service providers supported over <span class="stats">24,000</span> trips between April 2020 and March 2021.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='FromHospitaltoCommunity' class="content-container" name='From Hospital to Community'>
            <div class="titles">
                <h4>From Hospital to Community</h4>
            </div>
        </section>
        <section id='HospitaltoHome' class="content-container" name='Hospital-to-Home (H2H)'>
            <div class="titles">
                <h5>Hospital-to-Home (H2H)</h5>
            </div>
            <div class="col-12 px-3">
                <p>Under this programme, hospital teams provide support to patients during their transition from hospital back home. H2H care teams work with other Community Care partners to support and monitor patients through home visits and phone calls. They also ensure a coordinated and smooth transfer of care to the Community Care providers after the transitional care phase.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_52.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Close to <span class="stats">68,000</span> seniors have benefited from an earlier discharge thanks to this initiative.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='OutpatienttoCommunity' class="content-container" name='Outpatient to Community'>
            <div class="titles">
                <h5>Outpatient to Community</h5>
            </div>
            <div class="col-12 px-3">
                <p>Through this programme, patients who have been going to specialist outpatient clinics for their chronic health conditions can now follow up at GP clinics or polyclinics when their condition stabilises.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_53.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>More than <span class="stats">9,000</span> patients have benefited from this initiative.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='NursingHomes' class="content-container" name='Nursing Homes'>
            <div class="titles">
                <h4>Nursing Homes</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_24.jpg" alt="">
                    <p class="credits">Credit: Sree Narayana Mission (Singapore)</p>
                </div>
            </div>
            <p class="caption px-3">Nursing homes are increasingly leveraging technology, getting their residents to participate in online activities</p>
            <div class="col-12 px-3">
                <p>Nursing homes are long-term residential care facilities that support residents who need help in most activities of daily living and daily nursing care needs. There are also selected nursing homes with specific dementia or psychiatric facilities that cater to residents who need specialised care and support.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_54.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>There are more than <span class="stats">70</span> nursing homes in Singapore.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='AdvanceCarePlanning' class="content-container" name='Advance Care Planning (ACP)'>
            <div class="titles">
                <h4>Advance Care Planning (ACP)</h4>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/uW8RwTki2AU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p>ACP is a guided process for Singaporeans to plan ahead for how they wish to be cared for, should they become unable to make decisions for themselves. It involves conversations with loved ones and healthcare providers to discuss and document personal values and preferred care options. <br><br>Since 2019, we have worked with Tote Board and service providers to expand public access to ACP through community nodes. From April 2020, ACP is also available as a teleconsultation service at all ACP community nodes and selected hospitals. <br><br> In 2020, we worked with partners to train more ACP facilitators, leveraging on online training. We also developed a post-training roadmap for facilitators with competency guidelines, informal mentorship and other resources to deepen skills and build confidence.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_55.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Between April 2019 and March 2021, over <span class="stats">11,000</span> advance care plans were completed.</p>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_56.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">180</span> ACP awareness events reached more than <span class="stats">2,000</span> people during the same period.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_57.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">400</span> healthcare providers underwent online facilitator training.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'FacilitatingCareServices',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>
<style scoped>
a {
    color: #DA3305;
}
a:hover {
    text-decoration: underline;
}
</style>

