<template>
    <div>
        <section id='CommunityMentalHealthNetwork' class="content-container" name='Community Mental Health Network'>
            <div class="titles">
                <h2>Supporting Community Mental Health</h2>
                <h4>Community Mental Health Network</h4>
            </div>
            <div class="col-12 px-3">
                <p>This integrated network supports persons with mental health issues and dementia, as well as their caregivers, so that they can age well in the community. The network comprises community outreach teams, community intervention teams, primary care providers, specialist-led hospital teams and community partners. <br><br> Community outreach teams raise awareness of mental health conditions and identify those at risk early. They also provide emotional support to clients and link them up with health and social care services. Community intervention teams provide assessment and counselling services, supporting the community outreach teams and primary care providers. GPs and polyclinics bring mental health services closer to home, while Assessment and Shared Care Teams in hospitals support those who require a higher level of clinical care. These specialist-led teams also build the capability of primary care and Community Care partners to provide mental health support in the community. The network is supported by CareInMind (careinmind@aic.sg), which provides overall care coordination.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-md-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_68.png" alt="">
                        </div>
                        <div class="col-md-8 text-2">
                            <p>
                                As of December 2020, there are
                                <br>
                                <span class="stats">50</span> community outreach teams, 
                                <br>
                                <span class="stats">21</span> community intervention teams, 
                                <br><span class="stats">10</span> Assessment and Shared Care Teams,
                                <br>
                                over <span class="stats">220</span> GPs and <span class="stats">14</span> polyclinics in this network.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='DementiaFriendlySingapore' class="content-container" name='Dementia-Friendly Singapore (DFSG)'>
            <div class="titles">
                <h4>Dementia-Friendly Singapore (DFSG)</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_25.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">Meet Giffy the giraffe, our Dementia Friends mascot</p>
            <div class="col-12 px-3">
                <p>Dementia is expected to affect over 100,000 Singaporeans aged 60 and above by 2030. DFSG is a national initiative by MOH and AIC to build an inclusive society that supports persons living with dementia and their families, so that they can lead independent lives.<br><br> Our key strategies are engaging communities and businesses to form a supportive network, empowering at-risk individuals with accessible care services and resources, and enabling partners to adopt good practices such as safe environments and innovative technology.</p>
            </div>
        </section>
        <section id='DementiaFriendlyCommunity' class="content-container" name='Dementia-Friendly Community (DFC)'>
            <div class="titles">
                <h5>Dementia-Friendly Community (DFC)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_26.jpg" alt="">
                </div>
            </div>
            <div class="col-12 px-3">
                <p>A DFC is a community where its members (e.g. residents, businesses, schools, public service and voluntary welfare agencies) are aware of dementia, supportive of residents living with the condition and work to make their neighbourhoods safe, inclusive and easy to navigate.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_58.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>To date, we have established <span class="stats">14</span> DFCs across Singapore.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='GoToPoints' class="content-container" name='Go-To Points (GTPs)'>
            <div class="titles">
                <h5>Go-To Points (GTPs)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_27.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">A GTP located at a nursing home</p>
            <div class="col-12 px-3">
                <p>A GTP is a resource centre for residents and caregivers and serves as a safe return point for persons living with dementia who get lost. Staff at GTPs are trained to calm them down and assist them in contacting their next-of-kin. GTPs are located at Community Care facilities, community clubs, and religious, commercial and transport sites.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_59.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p class="line">There are over <span class="stats">300</span> GTPs located islandwide.</p>
                            <p>AIC worked with SMRT to establish over <span class="stats">20</span> train stations and bus interchanges as GTPs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='DementiaFriendsMobileApp' class="content-container" name='Dementia Friends Mobile App'>
            <div class="titles">
                <h5>Dementia Friends Mobile App</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_28.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">The app can be downloaded from <a href="https://apps.apple.com/sg/app/dementia-friends/id1398198593">App Store</a> or <a href="https://play.google.com/store/apps/details?id=aic.dfriends&hl=en_SG&gl=US">Google Play Store</a></p>
            <div class="col-12 px-3">
                <p>Jointly developed with Nanyang Polytechnic and Integrated Health Information Systems, the Dementia Friends mobile app provides resources and support on dementia. Caregivers can also use the app to trace loved ones that go missing or keep a lookout for lost persons reported.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_60.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">10,000</span> users have downloaded the app and signed up as Dementia Friends.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_61.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">300</span> reported cases of lost seniors have been received via the app.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='BuildingPartnerCapabilities' class="content-container" name='Building Partner Capabilities'>
            <div class="titles">
                <h5>Building Partner Capabilities</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-seniors/img/BAU Seniors_29.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">Participants at a 2019 specialist training workshop on hoarding</p>
            <div class="col-12 px-3">
                <p>To enhance the capabilities of community partners in supporting clients with mental health issues and their caregivers, AIC developed a Mental Health Competency Framework and a Dementia Care Competency Framework. They outline the competency standards and requirements among different players in the community mental health landscape, from professionals to volunteers. <br><br> AIC also co-organised the Singapore Mental Health Conference in January 2019 and the Together Against Stigma Conference in October 2019. Delegates ranged from healthcare professionals and academics to caregivers and persons living with mental health conditions. In September 2019, we organised a specialist training workshop on hoarding, where agencies came together to exchange ideas and learn about case management approaches.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-seniors/infographics/BAU Seniors_Infographics_62.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">1,000</span>  delegates took part in the various events.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'SupportingCommunityMentalHealth',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>