<template>
	<div id="support-seniors" :class='fontsize'>
    
		<Intro :intro="intro" />

		<div class="container-fluid secondary-section fontsize" id="secondary-content">
			<div class="row">
  			 
  			  <SubNavOne :navLinks='navLinks'/>

			    <section id='Navigation' class="content ml-auto">

					<Description/>					
					
					<ConnectingThroughTouchpoints/>

                    <ReachingOutSpreadingWord/>

                    <ExtendingFinancialAssistance/>

                    <SupportingCaregivers/>

                    <FacilitatingCareServices/>

                    <SupportingCommunityMentalHealth/>

                    <StrengtheningPrimaryCare/>

				</section>

				<PageNavigation prev="/support-public" next="/support-partners" />

			</div>
		</div>
	</div>	
</template>

<script>
// @ is an alias to /src
import SubNavOne from '@/components/SubNavOne.vue'
import NavLinks from '@/components/support-seniors/navLinks.js'

import Intro from '@/components/Intro.vue'
import Description from '@/components/support-seniors/Description.vue'
import ConnectingThroughTouchpoints from '@/components/support-seniors/ConnectingThroughTouchpoints.vue'
import ReachingOutSpreadingWord from '@/components/support-seniors/ReachingOutSpreadingWord.vue'
import ExtendingFinancialAssistance from '@/components/support-seniors/ExtendingFinancialAssistance.vue'
import SupportingCaregivers from '@/components/support-seniors/SupportingCaregivers.vue'
import FacilitatingCareServices from '@/components/support-seniors/FacilitatingCareServices.vue'
import SupportingCommunityMentalHealth from '@/components/support-seniors/SupportingCommunityMentalHealth.vue'
import StrengtheningPrimaryCare from '@/components/support-seniors/StrengtheningPrimaryCare.vue'

import PageNavigation from '@/components/PageNavigation.vue'

import dynamicFontSizeMixin from '@/mixin/dynamicFontSizeMixin'

export default {
		name: 'SupportSeniors',

    data() {
        return {
            scroll: 0,
            sections: '',
            currentSec: '',
            navLinks : NavLinks,
            hashes: [
            ],
            intro: {
                title: 'Building a stronger care community',
                secondTitle: 'Support for seniors, clients & caregivers',
                img: this.mobileAndTabletCheck() ? require("@/assets/support-seniors/banner/mobile.jpg") : require("@/assets/support-seniors/banner/desktop.jpg")
            }
    	}
    },

    mixins: [dynamicFontSizeMixin],

    components: {
        SubNavOne,
        Intro,
        Description,
        ConnectingThroughTouchpoints,
        ReachingOutSpreadingWord,
        ExtendingFinancialAssistance,
        SupportingCaregivers,
        FacilitatingCareServices,
        SupportingCommunityMentalHealth,
        StrengtheningPrimaryCare,
        PageNavigation
    },

    mounted() {
        
        const hash = this.$route.hash
        if (hash) {
            this.$nextTick(async () => {
                console.log('Support Senior :mounted')
                const currentPosition = window.scrollY
                if (currentPosition === 0) {
                    let hashPosition = document.getElementById(hash.substring(1))
                    hashPosition = hashPosition.getBoundingClientRect()

                    /* mobile only */
                    var adjust_ = 35
                    if(document.documentElement.clientWidth < 760) adjust_ = 50;

                    window.scrollTo(0, currentPosition + hashPosition.y - adjust_)
                    console.log(currentPosition, hashPosition.y)
                } else {
                    let scrollTo = 0
                    await new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const currentPosition = window.scrollY
                            let hashPosition = document.getElementById(hash.substring(1))
                            
                            
                            hashPosition = hashPosition.getBoundingClientRect()
                            scrollTo = currentPosition + hashPosition.y
                            resolve(true)
                        }, 150)
                    })
                    console.log('current position not 0')
                    window.scrollTo(0, scrollTo)
                }
            })
        }

    }
}
</script>

<style>
 /* media */
@media (min-width: 768px) {
	.content{
	 	width: 70vw;
	}
}
</style>