<template>
    <section class="content-container row justify-content-center" id='description' name='Support for seniors, clients & caregivers'>
        <div class="col-12 px-3">
            <p>It takes a community to support seniors and clients, as well as their caregivers, to fulfil their desire to age with dignity. AIC is building strong networks of support across the island to engage with seniors and support them in staying active, link caregivers up with information and support, and bring care services and financial assistance to those who need it.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Description',
}
</script>
